// example from 
// https://github.com/reduxjs/redux/blob/master/examples/todos/src/reducers/todos.js

const user = (state = 'waiting', action) => {
    switch (action.type) {
        case 'SET_USER':
            return action.data
        default:
            return state
    }
}

export default user