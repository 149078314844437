// example from 
// https://github.com/reduxjs/redux/blob/master/examples/todos/src/reducers/todos.js

const table_status = (state = {}, action) => {
    switch (action.type) {
        case 'SET_TABLE_STATUS':
            return {
                ...state, 
                [action.tablename]:{
                    ...state[action.tablename],
                    ...action.data
                }
            }
        default:
            return state
    }
}

export default table_status