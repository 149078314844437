// example from 
// https://github.com/reduxjs/redux/blob/master/examples/todos/src/reducers/todos.js

const alerts = (state = [], action) => {
    switch (action.type) {
        case 'REFRESH_ALERT':
            return [
                ...(state.filter((error) => {
                    return new Date(error.created.getTime() + error.timeout) > new Date().getTime()
                }))
            ]
        case 'PUSH_ALERT':
            if (~('created' in action.data)) action.data['created'] = new Date()
            return [
                ...(state.filter((error) => {
                    return new Date(error.created.getTime() + error.timeout) > new Date().getTime()
                })), action.data
            ]
        default:
            return state
    }
}

export default alerts