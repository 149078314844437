import React, { Suspense, lazy }  from "react";
import { ThemeProvider } from "@material-ui/core";
import {
  HashRouter as Router
} from "react-router-dom";

import theme from "./theme";
import CircularProgress from '@material-ui/core/CircularProgress';
// import BaseLayout from "./BaseLayout"
const BaseLayout = lazy(()=> import("./BaseLayout"))

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<CircularProgress disableShrink style={{margin:'auto'}}/>}>
          <BaseLayout />
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}
